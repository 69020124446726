<template>
  <div class="row m-0 align-items-center borders py-3">
    <div class="col-10 green-text bold item">
      <SelectProjects v-model="selectedProject" :originalProjectID="donation.project.projectID" width="full" />
    </div>
    <div class="col-12">
      <div class="row m-0 align-items-center">
        <div class="col-9 p-0">
          <div class="row">
            <div class="col-11 col-md-auto mt-2" >
              <CurrencyField :label="value.donationType.description" v-model="value.amount" size="small" color="outline-green" />
            </div>
          </div>
        </div>
        <div class="col-12 col-md-3 bold text-end">
          {{ value.currency.symbol }} {{ parseFloat(Math.round(value.amount * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineAsyncComponent } from 'vue'
import SelectProjects from '@/components/projects/SelectProjects.vue'

export default {
  components: {
    CurrencyField: defineAsyncComponent(() => import('@/components/CurrencyField.vue')),
    SelectProjects
  },
  name: 'EditDonationItem',
  props: ['modelValue', 'donation'],
  emits: ['update:modelValue'],
  data () {
    return {
      selectedProject: {}
    }
  },
  mounted () {
    this.selectedProject.projectID = this.donation.projectID
    this.selectedProject.description = this.donation.description
    this.selectedProject.section18ATAX = this.donation.section18ATAX
  },
  computed: {
    value: {
      get () {
        return this.modelValue
      },
      set (val) {
        this.setRand(val.amount)
        this.$emit('update:modelValue', val)
      }
    },
    editedProject () {
      const obj = {
        description: '',
        projectID: null,
        section18ATAX: false
      }
      obj.projectID = this.selectedProject.projectID
      obj.description = this.selectedProject.description
      obj.section18ATAX = this.selectedProject.section18ATAX
      return obj
    }
  },
  watch: {
    selectedProject: {
      deep: true,
      async handler (val) {
        if (this.selectedProject && this.selectedProject.projectID && this.selectedProject.projectID !== this.donation.project.projectID) {
          this.setTypeAmounts()
          this.value.project = { ...this.editedProject }
        }
      }
    }
  },
  methods: {
    setTypeAmounts () {
      if (this.selectedProject && this.selectedProject.projectDonationTypeMaps) {
        const pos = this.selectedProject.projectDonationTypeMaps.map(e => e.donationType.donationTypeID).indexOf(this.value.donationType.donationTypeID)
        if (pos === -1) {
          this.value.donationType.donationTypeID = this.selectedProject.projectDonationTypeMaps[0].donationType.donationTypeID
          this.value.donationType.description = this.selectedProject.projectDonationTypeMaps[0].donationType.description
        }
      }
    },
    setRand (val) {
      console.log('SET RND', val)
      this.value.estimatedRandValue = val
    }
  }
}
</script>
<style scoped>
.borders {
  border-bottom: 1px solid #E0E0E0;
}
.borders:hover {
  background-color: rgb(244, 244, 244);
}

</style>
