<template>
  <div class="col-12 px-4 align-self-end background-green">
    <div class="row py-3 align-items-center justify-content-center">
      <div class="col-8 col-md-5 px-2 mb-2 mb-md-0" >
        <Button color="red" size="" width="100" btnText="Cancel Changes" icon="arrow" @buttonClicked="$emit('closeEdit')">
          <ios-close-circle-outline-icon class="inline-icon" />
        </Button>
      </div>
      <div class="col-8 col-md-5 px-2" >
        <Button color="green_light" size="" width="100" btnText="Save Changes" icon="arrow" @buttonClicked="save" :isLoading="isLoading">
          <IconEdit size="size16"/>
        </Button>
      </div>
    </div>
  </div>
</template>
<script>
import { defineAsyncComponent } from 'vue'
import { mapActions } from 'vuex'

export default {
  components: {
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    IconEdit: defineAsyncComponent(() => import('@/components/icons/IconEdit.vue')),
    'ios-close-circle-outline-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-close-circle-outline.vue'))
  },
  name: 'EditRecurringActionButtons',
  props: ['item'],
  emits: ['closeEdit'],
  data () {
    return {
      isLoading: false
    }
  },
  methods: {
    ...mapActions(['updateRecurringDonation']),
    async save () {
      this.isLoading = true
      const ret = await this.updateRecurringDonation(this.item)
      console.log('RET SAVE Edity', ret)
      if (ret) {
        this.isLoading = false
        this.$emit('closeEdit')
      }
    }
  }
}
</script>
<style scoped>

.card_item_inner {
  background-color: rgb(249, 250, 251);
  border-radius: 15px;
  /* box-shadow: 2px 8px 23px 3px rgb(0 0 0 / 20%); */
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.card_title {
  font-family: "quicksand_medium", Sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  color: var(--green-color-dark);
  letter-spacing: 4.3px;
}
.border_bottom {
  border-bottom: 1px solid var(--gold-color);
}
.borders {
  border-bottom: 1px solid #E0E0E0;
}
.borders:hover {
  background-color: rgb(244, 244, 244);
}

.selectFrequency .form-control {
    background-color: var(--green-color-dark);
    color: var(--gold-color);
    border-radius: 25px;
    border: 2px solid var(--green-color-dark);
    font-size: 1rem;
    height: calc(2.5rem + 4px);
    padding: 0;
    font-weight: 500;
    text-align: center;
  }
  .selectFrequency .form-control:focus {
    background-color: transparent;
    border-color: transparent;
    outline: 0;
    box-shadow: none;
  }
  .selectFrequency .form-control-inner {
    background-color: transparent;
    position: relative;
    color: var(--gold-color);
    border-radius: 25px;
    border: none;
    font-size: 1rem;
    height: calc(2.5rem + 4px);
    padding: 0 1rem;
    font-weight: 500;
    text-align: center;
    z-index: 2;
  }

  .selectFrequency .holder {
    position: relative;
  }
  .selectFrequency .dropdown_icon {
    position: absolute;
    color: var(--gold-color);
    top: 0.8rem;
    right: 0.7rem;
    z-index: 1;
  }
</style>
