<template>
  <div class="row m-0 justify-content-center" >
    <div class="col-md-11 px-0 my-3">
      <div class="row align-items-center">
        <div class="col-md-4 text-center green-text mb-3 mb-md-0">
          <TextField type="text" color="gold" placeholder="Total" v-model="displayTotal" disabled />
          <div class="medium green-text font10 letter_spacing mt-2">
            DONATION TOTAL
          </div>
        </div>
        <div class="col-md-4 text-center mb-3 mb-md-0">
          <div class="row m-0 selectFrequency">
            <div class="col-12 form-control holder">
              <select
                v-model="value.frequency"
                class="form-control form-control-inner w-100 medium "
              >
                <option :value="option.val" v-for="option in frequencyOptions" :key="option"  class="normal-text-dark">{{option.display}}</option>
              </select>
              <div class="dropdown_icon"><ios-arrow-down-icon /></div>
            </div>
          </div>
          <div class="medium green-text font10 letter_spacing mt-2">
            FREQUENCY
          </div>
        </div>
        <div class="col-md-4 text-center green-text mb-3 mb-md-0">
          <TextField type="text" color="gold" placeholder="Start Date" v-model="date" disabled />
          <div class="medium green-text font10 letter_spacing mt-2">
            NEXT PAYMENT
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineAsyncComponent } from 'vue'
import { DateTime } from 'luxon'

export default {
  components: {
    'ios-arrow-down-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-arrow-down.vue')),
    TextField: defineAsyncComponent(() => import('@/components/TextField.vue'))
  },
  name: 'EditRecurringSummary',
  props: ['modelValue'],
  emits: ['update:modelValue'],
  data () {
    return {
      frequencyOptions: [
        {
          val: 'DAILY',
          display: 'Daily'
        },
        {
          val: 'WEEKLY',
          display: 'Weekly'
        },
        {
          val: 'MONTHLY',
          display: 'Monthly'
        }
      ]
    }
  },
  computed: {
    value: {
      get () {
        return this.modelValue
      },
      set (val) {
        this.$emit('update:modelValue', val)
      }
    },
    displayTotal () {
      if (this.value.donations.length > 0) {
        let ret = 0
        this.value.donations.forEach(item => {
          ret += Number(item.amount)
        })
        this.setTotal(ret)
        return this.value.donations[0].currency.symbol + ' ' + parseFloat(Math.round(ret * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
      } else {
        return parseFloat(Math.round(0 * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
      }
    },
    date () {
      const debitOrderDay = this.value.day
      const startMonth = DateTime.fromMillis(this.value.startDate).month
      const startYear = DateTime.fromMillis(this.value.startDate).year
      const startDate = DateTime.fromObject({ year: startYear, month: startMonth, day: debitOrderDay }).toMillis()
      const now = DateTime.now().ts
      const frequency = this.value.frequency
      let nextPaymentDate = null
      if (now > startDate) {
        if (frequency === 'DAILY') {
          nextPaymentDate = DateTime.now().startOf('day').plus({ days: 1 })
        } else if (frequency === 'WEEKLY') {
          const diff = Math.ceil(Math.abs(DateTime.fromMillis(startDate).diffNow('weeks').weeks))
          nextPaymentDate = DateTime.fromMillis(startDate).plus({ weeks: diff })
        } else if (frequency === 'MONTHLY') {
          const diff = Math.ceil(Math.abs(DateTime.fromMillis(startDate).diffNow('months').months))
          nextPaymentDate = DateTime.fromMillis(startDate).plus({ months: diff })
        }
      } else {
        nextPaymentDate = DateTime.fromMillis(startDate)
      }
      return DateTime.fromMillis(nextPaymentDate.ts).toFormat('yyyy-MM-dd')
    }
  },
  methods: {
    setTotal (val) {
      this.value.amount = val
    }
  }
}
</script>
<style scoped>
.selectFrequency .form-control {
    background-color: var(--green-color-dark);
    color: var(--gold-color);
    border-radius: 25px;
    border: 2px solid var(--green-color-dark);
    font-size: 1rem;
    height: calc(2.5rem + 4px);
    padding: 0;
    font-weight: 500;
    text-align: center;
  }
  .selectFrequency .form-control:focus {
    background-color: transparent;
    border-color: transparent;
    outline: 0;
    box-shadow: none;
  }
  .selectFrequency .form-control-inner {
    background-color: transparent;
    position: relative;
    color: var(--gold-color);
    border-radius: 25px;
    border: none;
    font-size: 1rem;
    height: calc(2.5rem + 4px);
    padding: 0 1rem;
    font-weight: 500;
    text-align: center;
    z-index: 2;
  }

  .selectFrequency .holder {
    position: relative;
  }
  .selectFrequency .dropdown_icon {
    position: absolute;
    color: var(--gold-color);
    top: 0.8rem;
    right: 0.7rem;
    z-index: 1;
  }
</style>
