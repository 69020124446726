<template>
  <div class="row mx-0" v-if="selectedProject">
    <div class="col-12 p-0 mb-3" v-if="parentProjects && parentProjects.length > 0">
      <SelectAll
        v-model="selectedParentProject"
        :options="parentProjects"
        placeholder="Select Project"
        displayValue="description"
        color="white"
        :isSearch="true"
        :width="width"
      />
    </div>
    <div class="col-12 p-0 mb-3" v-if="selectedParentProject && Object.keys(selectedParentProject).length !== 0 && childProjects.length > 0">
      <SelectAll
        v-model="selectedChildProject"
        :options="childProjects"
        placeholder="Select Child Project"
        displayValue="description"
        color="white"
        :isSearch="true"
        :width="width"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    SelectAll: defineAsyncComponent(() => import('@/components/SelectAll.vue'))
  },
  name: 'SelectProjectsComponent',
  props: ['modelValue', 'originalProjectID', 'width'],
  emits: ['update:modelValue'],
  data () {
    return {
      selectedProject: {},
      selectedParentProject: {},
      selectedChildProject: {},
      editItem: {}
    }
  },
  async mounted () {
    console.log('SELECTPROJECT <OUNTED', this.originalProjectID)
    this.editItem = { ...this.modelValue }
    await this.matchProject()
  },
  watch: {
    editItem: {
      deep: true,
      async handler (val) {
        await this.$emit('update:modelValue', val)
      }
    },
    async selectedProject () {
      if (this.selectedProject && Object.keys(this.selectedProject).length > 0) {
        this.editItem = this.selectedProject
      }
    },
    async selectedParentProject () {
      if (this.selectedParentProject && Object.keys(this.selectedParentProject).length > 0) {
        if (this.selectedChildProject && Object.keys(this.selectedChildProject).length > 0) {
          this.selectedChildProject = {}
          this.selectedProject = this.selectedParentProject
        } else {
          this.selectedProject = this.selectedParentProject
        }
      }
    },
    async selectedChildProject () {
      if (this.selectedChildProject && Object.keys(this.selectedChildProject).length > 0) {
        this.selectedProject = this.selectedChildProject
      }
    }
  },
  computed: {
    ...mapGetters([
      'adminProjects',
      'contentProjects'
    ]),
    value: {
      get () {
        return this.modelValue
      },
      set (val) {
        this.$emit('update:modelValue', val)
      }
    },
    parentProjects () {
      return this.adminProjects.filter(project => {
        if (project.parentProjectID !== 0) {
          return false
        }
        return true
      })
    },
    childProjects () {
      const arr = this.adminProjects.filter(project => {
        if (this.selectedParentProject && project.parentProjectID !== this.selectedParentProject.projectID) {
          return false
        }
        return true
      })
      arr.sort((a, b) => (a.description > b.description ? 1 : -1))
      return arr
    },
    originalProjectDetails () {
      const adminProject = this.adminProjects.find(project => project.projectID === this.originalProjectID)
      const contentProject = this.contentProjects.find(project => project.adminProjectId === this.originalProjectID)
      if (contentProject) {
        const mergedProject = { ...contentProject, ...adminProject }
        return mergedProject
      } else {
        return adminProject
      }
    }
  },
  methods: {
    async matchProject () {
      console.log('match')
      if (this.originalProjectDetails) {
        if (this.originalProjectDetails.parentProjectID === 0 || this.originalProjectDetails.parentProjectID === '0') {
          this.selectedParentProject = this.originalProjectDetails
        } else if (this.originalProjectDetails.parentProjectID && this.originalProjectDetails.parentProjectID !== 0) {
          await this.getParentProject(this.originalProjectDetails.parentProjectID)
          this.selectedChildProject = this.originalProjectDetails
        }
      }
    },
    getParentProject (id) {
      const theProject = this.adminProjects.filter(project => {
        if (project.projectID !== id) {
          return false
        }
        return true
      })
      this.selectedParentProject = theProject[0]
    }
  }
}
</script>
